import $ from "jquery";

const getData = () => {
    $.ajax({
        url: "getCollected.php",
        //url: "getCollected.json",
        method: "GET",
        dataType: "json",
    })
        .done(function (response) {
            $('#collected').val(response.collected)
            $('#collectedText').val(response.collectedText)
            progressBarCalc();
        })
    // window.requestAnimationFrame(getData)
}
getData()

const progressBarCalc = () => {
    const collectedAll = parseInt($('#collected').val())
    let collected = collectedAll
    let collectedText = $('#collectedText').val()
    $('.collectedText').text(collectedText)
    let EtapeOk = false;
    $("[role=progressbar]").each(function () {
        let areaText = $(this).next();
        let stepValue = parseInt($(this).attr('aria-valuenow'));
        let progress = 0;
        if (collected >= stepValue) {
            progress = 100;
            collected=collected-stepValue;
            console.log(collected)
            console.log(collected/100)
            console.log((collected/100) + ' €')
            collectedText=(collected/100) + ' €'
        } else {
            progress = (collected / stepValue) * 100;
        }
        if (progress < 1) progress = 1;
        $(this).find('.h1').attr('style', 'width:' + progress + '%')

        if (progress == 100) {
            $(this).find('.h1').addClass('bg-ksr-golden-500')
        }

        if (progress < 100) {
            if (!EtapeOk) {
                areaText.find('.collectedText').html(collectedText)
                EtapeOk = true;
            } else {
                areaText.find('.collectedText').html('En attente')
                areaText.find('.engageText').html('Prochain objectif : ')
            }

        } else {
            areaText.find('.engageText').html('de')
            areaText.find('.collectedText').html('Objectif atteint').addClass('ksr-golden-500')
        }

    })
}


const timerDay = () => {
    var compte_a_rebours = document.getElementById("compte_a_rebours");

    var date_actuelle = new Date();
    var date_evenement = new Date("jan 25 00:00:00 2024");
    var total_secondes = (date_evenement - date_actuelle) / 1000;

    var prefixe = " ";
    if (total_secondes < 0) {
        prefixe = "Compte à rebours terminé il y a "; // On modifie le préfixe si la différence est négatif
        total_secondes = Math.abs(total_secondes); // On ne garde que la valeur absolue
    }

    if (total_secondes > 0) {
        var jours = Math.floor(total_secondes / (60 * 60 * 24));
        var heures = Math.floor((total_secondes - (jours * 60 * 60 * 24)) / (60 * 60));
        var minutes = Math.floor((total_secondes - ((jours * 60 * 60 * 24 + heures * 60 * 60))) / 60);
        var secondes = Math.floor(total_secondes - ((jours * 60 * 60 * 24 + heures * 60 * 60 + minutes * 60)));

        var et = "et";
        var mot_jour = "jours,";
        var mot_heure = "heures,";
        var mot_minute = "minutes,";
        var mot_seconde = "secondes";

        if (jours == 0) {
            jours = '';
            mot_jour = '';
        } else if (jours == 1) {
            mot_jour = "jour,";
        }

        if (heures == 0) {
            heures = '';
            mot_heure = '';
        } else if (heures == 1) {
            mot_heure = "heure,";
        }

        if (minutes == 0) {
            minutes = '';
            mot_minute = '';
        } else if (minutes == 1) {
            mot_minute = "minute,";
        }

        if (secondes == 0) {
            secondes = '';
            mot_seconde = '';
            et = '';
        } else if (secondes == 1) {
            mot_seconde = "seconde";
        }

        if (minutes == 0 && heures == 0 && jours == 0) {
            et = "";
        }

        compte_a_rebours.innerHTML = prefixe + jours + ' ' + mot_jour + ' ' + heures + ' ' + mot_heure + ' ' + minutes + ' ' + mot_minute + ' ' + et + ' ' + secondes + ' ' + mot_seconde;
    } else {
        compte_a_rebours.innerHTML = 'Compte à rebours terminé.';
    }
    window.requestAnimationFrame(timerDay)
}
timerDay()